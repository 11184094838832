import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBkT27XfedquxXP5Di8OrlFfhczJBZM5d0",
    authDomain: "website-front-4c93b.firebaseapp.com",
    projectId: "website-front-4c93b",
    storageBucket: "website-front-4c93b.firebasestorage.app",
    messagingSenderId: "225264085691",
    appId: "1:225264085691:web:f56dfc76e27b76516ed79e",
    measurementId: "G-3JKVBXHPDW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);