import React from "react";
import bannerImg from "../../../../assets/images/banner-img.png";
import Translator from "../../../../components/Translator";
import "./style.scss";

const translatorId = "page-home";

const MainBanner = () => {
    return (
        <div className="home-main-banner">
            <img
                alt="generic banner"
                src={bannerImg}
                className="right-img-banner"
            />
            <div className="text-area">
                <h2>
                    <Translator
                        path={`${translatorId}.main-banner.carousel.item1.title`}
                    />
                </h2>
                <p>
                    <Translator
                        path={`${translatorId}.main-banner.carousel.item1.description`}
                    />
                </p>
            </div>
        </div>
    )
}

export default MainBanner;