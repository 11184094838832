import React from "react";
import { useForm } from "react-hook-form"
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../firebase";
// Components
import { Form, Button } from "react-bootstrap"
import { toast } from "react-toastify";
import Translator from "../../../../components/Translator";
import { useTranslation } from "react-i18next";

const translatorId = "page-home";

const NewsLetter = () => {
    const [loading, setLoading] = React.useState(false);
    const { register, handleSubmit, formState: { errors }, resetField } = useForm()
    const { t } = useTranslation();

    const onSubmit = (data) => {
        setLoading(true);
        addDoc(collection(db, "news_letter"), {
            createdAt: serverTimestamp(),
            email: data.email,
        })
            .then(() => {
                toast.success(t(`${translatorId}.main-banner.news-letter-success`))
                resetField("email");
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="left-banner-details">
            <h5>
                <Translator path={`${translatorId}.main-banner.subtitle`} />
            </h5>
            <h2>
                <Translator path={`${translatorId}.main-banner.title`} />
                {" "}
                <span>
                    <Translator path={`words.amazon`} />
                </span>
            </h2>
            <p>
                <Translator path={`${translatorId}.main-banner.description`} />
            </p>
            <div className="email-letter-wrap">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder={Translator({
                                path: `${translatorId}.main-banner.input-placeholder`,
                            })}
                            required
                            {...register("email", {
                                required: true,
                                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            })}
                        />
                        {errors.email && (
                            <Form.Text style={{ color: 'var(--bs-danger)' }}>
                                <Translator path={`${translatorId}.main-banner.input-error`} />
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Button disabled={loading} className="begin-btn-wrap" type="submit">
                        <Translator
                            path={`${translatorId}.main-banner.button-label`}
                        />
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default NewsLetter;