// eslint-disable-next-line import/no-anonymous-default-export
export default {
    translations: {
        words: {
            amazon: "Amazon.",
            green: "Green.",
            "get-started": "Get Started",
            register: "Register",
        },

        header: {
            description: "Invest with purpose through BVM12!",
            "menu": {
                "home": "Home",
                "aboutUs": "About Us",
                "products": "Products",
                "contact": "Contact"
            },
        },

        "final-banner": {
            text: "Take a step towards a better and more sustainable world today!",
        },

        footer: {
            pages: {
                label: "Pages",
                home: "Home",
                aboutUs: "About Us",
                products: "Products",
                contact: "Contact",
                ecoTrade: "EcoTrade",
                joinTeam: "Join Our Team",
                blog: "Blog",
                blogPost: "Blog Post",
                team: "Team",
                comingSoon: "Coming Soon",
            },
            "utility-pages": {
                label: "Utility Pages",
                startHere: "Start Here",
                styleGuide: "Style Guide",
                licenses: "Licenses",
            },
            contacts: {
                label: "Contact Us",
                email: "Send us an e-mail",
                call: "Send us a message",
            },
            followUs: "Follow Us",
        },

        "our-office": {
            subtitle: "Our Address",
            title: "Contact us in Manaus or the metaverse!",
            "city-description": "We are physically present in Manaus and virtually anywhere in the world or the metaverse.",
            button: "Get in touch",
        },

        "page-home": {
            name: "Home",
            "main-banner": {
                subtitle: "INVESTMENT WITH PURPOSE:",
                title: "The Green Exchange of Manaus in the heart of the",
                description:
                    "Resource mobilization in the Amazon, boosting local businesses and promoting sustainable growth with reforestation to neutralize the carbon footprint.",
                "input-placeholder": "Enter your email address",
                "input-error": "Please enter a valid email address",
                "button-label": "Subscribe to Newsletter",
                "news-letter-success": "Email saved to our News Letter!",
                carousel: {
                    item1: {
                        title: "Buy your tree in the Amazon now!",
                        description:
                            "By purchasing a tree, certified and tokenized in the Amazon, you are strengthening the environment and protecting the forest.",
                        buttonLabel: "Buy a tree",
                    },
                    item2: {
                        title: "Invest in the future of the Amazon!",
                        description:
                            "With BVM12, you contribute to a greener and more sustainable future while strengthening local businesses.",
                    },
                },
            },
            "our-features": {
                "section-name": "OUR FEATURES",
                title: "The features that make BVM12 unique.",
                cards: {
                    "invest-in-the-amazon": {
                        title: "Reforestation",
                        description:
                            "BVM12 conducts reforestation with certified and tokenized trees, preserving the greatest biodiversity and offsetting companies' carbon footprints.",
                    },
                    "invest-with-purpose": {
                        title: "Resource Mobilization",
                        description:
                            "BVM12 connects investment funds and banks to companies and governments in the Amazon region that need capital to foster their businesses and regions.",
                    },
                    "technology-meets-nature": {
                        title: "Carbon Credit",
                        description:
                            "BVM12 trades tokenized carbon credits, offering compensation solutions for CO2 emissions by companies and governments.",
                    },
                    "green-capital": {
                        title: "Green Assets",
                        description:
                            "BVM12 offers a variety of financial services with green assets to investors and financial institutions committed to the environment.",
                    },
                    palafita: {
                        title: "Palafita",
                        description:
                            "Palafita is the social cryptocurrency chosen by BVM12 to conduct buying and selling transactions within the asset trading platform.",
                    },
                    "cvm-approved": {
                        title: "Approved by CVM",
                        description:
                            "BVM12 has been approved by CVM, Brazil's Securities and Exchange Commission, to help startups raise funds through IPOs and private financing.",
                    },
                },
                "button-getstarted": "Get Started",
                "button-browse-features": "Explore all features",
            },


            "mid-banner": {
                subtitle: "OUR PRODUCTS",
                title:
                    "We are the next step in green resource mobilization and investments.",
                nft: {
                    title: "Resource Mobilization",
                    description:
                        "BVM12 leads responsible resource mobilization in the Amazon, driving sustainable development. By connecting investors to local businesses, we promote a balance between economic growth and environmental preservation.",
                },
                esg: {
                    title: "Reforestation",
                    description:
                        "BVM12 leads certified and tokenized reforestation through blockchain in the Amazon, restoring degraded areas. We contribute to environmental preservation, ensuring transparency and sustainability at every step.",
                },
                co2: {
                    title: "Carbon Credit",
                    description:
                        "BVM12 innovates by tokenizing carbon credits in the Amazon using blockchain. This solution enables efficient trading in the over-the-counter market, driving environmental preservation and offering a sustainable approach for investors.",
                },
            },


            "get-started": {
                subtitle: "Get Started",
                title: "It has never been easier to invest in assets",
                cards: {
                    first: {
                        title: "Register on our platform.",
                        description:
                            "Registration is simple and fast. Once completed, you will have an account to carry out financial transactions.",
                    },
                    second: {
                        title: "Choose a 'green token' of your choice.",
                        description:
                            "After registering your account, you will have access to the home broker to buy and sell your green tokens.",
                    },
                    third: {
                        title: "Safely purchase the token on the platform.",
                        description:
                            "Conduct transactions securely using Palafita as a payment method for acquiring tokens.",
                    },
                },
                "button-label": "Get Started",
            },
        },

        "page-products": {
            "name": "Products",
            "main-banner": {
                "subtitle": "Our Solutions",
                "title": "Revolutionizing Sustainability in the Amazon with Green Blockchain.",
                "description": "Our products leverage technology and creativity to generate value for the Amazon region and the environment.",
                "join-button-label": "Join our team",
                "our-company-button-label": "Our company's story"
            },

            "products": {
                "subtitle": "Reforestation",
                "title": "Our mission is to generate environmental and social impact in the Amazon",
                "description": "BVM12 is committed to reforesting the Amazon, promoting the recovery of degraded areas, and contributing to the preservation of local biomes. Our goal is to restore nature and create a positive impact on the environment.",
                "subtitle-section-two": "Carbon Credits",
                "title-section-two": "An effective solution to offset emissions",
                "description-section-two": "Carbon credits are an essential tool in the fight against climate change. BVM12 connects companies to certified projects, enabling emission offsets and promoting a more sustainable future.",
                "subtitle-section-three": "Fundraising",
                "title-section-three": "We democratize access to funds to boost companies",
                "description-section-three": "We offer fundraising solutions for startups focused on sustainability and companies in the Amazon region. Our goal is to connect entrepreneurs with investors, fostering innovative projects that promote economic and environmental development."
            },

            "our-values": {
                "subtitle": "Our Values",
                "title": "The values that guide everything we do",
                "inovation": {
                    "title": "Innovation",
                    "description": "We seek creative and technological solutions that turn challenges into opportunities, promoting sustainable and impactful changes in the Amazon and beyond."
                },
                "growth": {
                    "title": "Growth",
                    "description": "We believe in sustainable development as a pillar for a balanced future, promoting economic, social, and environmental growth in harmony."
                },
                "passion": {
                    "title": "Passion",
                    "description": "We are driven by a deep commitment to nature preservation and creating a positive impact for future generations."
                }
            }
        },

        "page-ecotrade": {
            name: "EcoTrade",
            banner: {
                subtitle: "EcoTrade",
                title: "Developed by BVM12, EcoTrade simplifies the direct sale of carbon credits for iFood delivery partners.",
                description: "The platform allows delivery partners to list and sell credits through iFood apps, using smart contracts for security and transparency."
            },
            story: {
                title1: "Integrated with Mastercard, providing an accessible and efficient experience.",
                description1: "Payments can be received in tokens or traditional currency, expanding transaction options. Additionally, to encourage sustainable practices, EcoTrade offers special discounts for iFood and BVM12 when purchasing carbon credits directly from delivery partners. Besides rewarding ecological efforts, EcoTrade strengthens collaboration among BVM12, iFood, and Mastercard for a greener economy.",
                title2: "Carbon Credit Certification",
                description2: "During the emissions certification phase, BVM12 takes a comprehensive approach. We collect detailed data from each electric motorcycle, including model, efficiency, and energy source. Performance is continuously monitored in real-time using sensors and telemetry.",
                title3: "Motorcycle Emissions Certification",
                description3: {
                    maintext: "By establishing a baseline with emissions from combustion motorcycles, BVM12 certifies the non-emissions of CO2, also considering the origin of the energy used to charge the batteries. Independent audits ensure data validity and integrity, providing reliable and transparent certification.",
                    callToAction: "Join us in this movement toward a sustainable future. EcoTrade by BVM12: connecting delivery partners, platforms, and carbon credits for a positive environmental impact.",
                    projectDetails: "EcoTrade is a project executed by BVM12 - Plataforma de Captação para Startups Ltda. CNPJ 38.730.632/0001-83",
                },
            },
        },

        "page-about-us": {
            "name": "About Us",
            "main-banner": {
                "subtitle": "About Us",
                "title": "We are on a mission to foster the economy in the Amazon.",
                "description":
                    "Through technology and creativity, we have been working to grow the economy and preserve the Amazon region, always with sustainable and scalable initiatives.",
                "join-button-label": "Join our team",
                "our-company-button-label": "Our company's story",
            },
            "why": {
                "subtitle": "Why?",
                "title": "What motivates us to work in the Amazon?",
                "description": "Our core purpose is to contribute to the sustainability and conservation of this vital ecosystem. By focusing on the 'Why,' we aim not only for tangible results but also for significant and positive impacts on the environment and local communities. We are committed to preserving the Amazon for future generations, and this conviction drives us daily in our work in the region."
            },
            "how": {
                "subtitle": "How?",
                "title": "How do we aim to foster the economy in the Amazon?",
                "description": "At BVM12, we use blockchain to simplify the buying and selling of carbon credits, driving reforestation in the Amazon. Transparent smart contracts encourage companies to offset emissions. Digital platforms connect investors to local projects, raising credit for companies and ensuring traceability of reforestation actions. This innovative approach not only promotes environmental preservation but also fosters sustainable economic development in the region, creating a lasting positive impact."
            },
            "who-we-are": {
                "subtitle": "What are we?",
                "title": "What is a Green Exchange?",
                "description": "BVM12 is a green economy business platform, leveraging blockchain to simplify carbon credit transactions and drive reforestation in the Amazon. Smart contracts encourage companies to offset emissions, while digital platforms connect investors to local projects, raising credit for companies. This innovative approach not only promotes environmental preservation but also stimulates sustainable economic development in the region, generating a significant and positive impact."
            }
        },

        "page-contact": {
            name: "Contact",
            form: {
                subtitle: "CONTACT US",
                title: "Get in touch today",
                name: {
                    label: "Name",
                    placeholder: "Enter your name",
                },
                email: {
                    label: "Email",
                    placeholder: "Enter your email",
                },
                phone: {
                    label: "Phone",
                    placeholder: "Enter your phone number",
                },
                subject: {
                    label: "Subject",
                    placeholder: "Enter your subject",
                },
                message: {
                    label: "How can we help?",
                    placeholder: "Please write your message here...",
                },
                "submit-button-label": "Send Message",
                "form-success": "Message sent successfully!",
            },
            "mid-banner": {
                subtitle: "BVM12",
                title: "Frequently Asked Questions",
            },
        },
    },
};