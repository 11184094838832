import React from "react";
import "./style.scss";
import logo from "../../assets/images/Logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import Translator from "../Translator";

const Header = () => {

  window.addEventListener("scroll", function () {
    let navbar = document.getElementById("menu-bar");
    if (window.pageYOffset >= 36) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  });

  return (
    <Navbar className="navbar-wrap" id="menu-bar" collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <NavLink to="/">
                <Translator path="page-home.name" />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/about">
                <Translator path="page-about-us.name" />
              </NavLink>
            </Nav.Link>
            {/*<Nav.Link>
              <NavLink to="/products">
                <Translator path="page-products.name" />
              </NavLink>
            </Nav.Link>*/}
            <Nav.Link>
              <NavLink to="/contact">
                <Translator path="page-contact.name" />
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/ecotrade">
                EcoTrade
              </NavLink>
            </Nav.Link>
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
