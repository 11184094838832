import React, { useEffect } from "react";
import "./styles/App.css";
import Routing from "./Routing";
import { useTranslation } from "react-i18next";
import { getUserLocale } from "./helper/Locale";
import { ToastContainer } from "react-toastify";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const userLocale = getUserLocale();
    i18n.changeLanguage(userLocale);
  }, [i18n]);

  return (
    <div className="App">
      <Routing />
      <ToastContainer />
    </div>
  );
}

export default App;
