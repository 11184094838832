// eslint-disable-next-line import/no-anonymous-default-export
export default {
    translations: {
        words: {
            amazon: "Amazônia.",
            green: "Verdes.",
            "get-started": "Comece agora",
            register: "Cadastre-se",
        },
        header: {
            description: "Invista com propósito pela BVM12!",
            "menu": {
                "home": "Início",
                "aboutUs": "Sobre Nós",
                "products": "Produtos",
                "contact": "Contato"
            },
        },

        "final-banner": {
            text: "Dê um passo em direção a um mundo melhor e mais sustentável hoje!",
        },

        footer: {
            pages: {
                label: "Páginas",
                home: "Início",
                aboutUs: "Sobre Nós",
                products: "Produtos",
                contact: "Contato",
                ecoTrade: "EcoTrade",
                joinTeam: "Junte-se ao Nosso Time",
                blog: "Blog",
                blogPost: "Post do Blog",
                team: "Equipe",
                comingSoon: "Em breve",
            },
            'utility.pages': {
                label: "Páginas de utilitários",
                startHere: "Iniciar aqui",
                styleGuide: "Guia de estilos",
                licenses: "Licenças",
            },
            contacts: {
                label: "Entre em contato conosco",
                email: "Envie-nos um e-mail",
                call: "Envie-nos uma mensagem",
            },
            followus: "Siga-nos",
        },
        "our-office": {
            subtitle: "Nosso endereço",
            title: "Fale conosco em Manaus ou no metaverso!",
            "city-description": "Estamos presentes fisicamente em Manaus e virtualmente em qualquer lugar do mundo ou do metaverso.",
            button: "Entre em contato",

        },

        "page-home": {
            name: "Início",
            "main-banner": {
                subtitle: "INVESTIMENTO COM PROPÓSITO:",
                title: "A Bolsa Verde de Manaus no coração",
                description:
                    "Captação de recursos na Amazônia, impulsionando negócios locais e promovendo crescimento sustentável com reflorestamento para neutralizar a pegada de carbono.",
                "input-placeholder": "Insira o seu endereço de email",
                "input-error": "Insira um email válido",
                "button-label": "Assinar Newsletter",
                "news-letter-success": "Email salvo em nossa news letter!",
                carousel: {
                    item1: {
                        title: "Compre sua árvore na Amazônia, agora!",
                        description:
                            "Ao comprar uma árvore, certificada e tokenizada na Amazônia, você está fortalecendo o meio ambiente e protegendo a floresta.",
                        buttonLabel: "Comprar árvore",
                    },
                    item2: {
                        title: "Invista no futuro da Amazônia!",
                        description:
                            "Com a BVM12, você contribui para um futuro mais verde e sustentável enquanto fortalece negócios locais.",
                    },
                },
            },

            "our-features": {
                "section-name": "NOSSOS RECURSOS",
                title: "Os recursos que tornam o BVM12 único.",
                cards: {
                    "invest-in-the-amazon": {
                        title: "Reflorestamento",
                        description:
                            "A BVM12 faz reflorestamento com árvores certificadas e tokenizadas, preservando a maior biodiversidade e compensando a pegada de carbono das empresas.",
                    },
                    "invest-with-purpose": {
                        title: "Captação de Recursos",
                        description:
                            "A BVM12 conecta fundos de investimentos e bancos a empresas e governos da região amazônica que precisam de capital para fomentarem seus negócios e regiões.",
                    },
                    "technology-meets-nature": {
                        title: "Crédito de Carbono",
                        description:
                            "A BVM12 negocia créditos de carbono tokenizados, oferecendo soluções de compensação para emissões de CO2 por empresas e governos.",
                    },
                    "green-capital": {
                        title: "Ativos Verdes",
                        description:
                            "A BVM12 oferece uma variedade de serviços financeiros com ativos verdes a investidores e instituições financeiras comprometidos com o meio ambiente.",
                    },
                    palafita: {
                        title: "Palafita",
                        description:
                            "Palafita é a criptomoeda social escolhida pela BVM12 para realizar transações de compra e venda dentro da plataforma de negociação de ativos.",
                    },
                    "cvm-approved": {
                        title: "Aprovado pela CVM",
                        description:
                            "A BVM12 foi aprovada pela CVM, Comissão de Valores Mobiliários do Brasil, para ajudar as startups a captar recursos por meio de IPOs e financiamento privado.",
                    },
                },
                "button-getstarted": "Iniciar",
                "button-browse-features": "Navegue por todos os recursos",
            },
            "mid-banner": {
                subtitle: "NOSSOS PRODUTOS",
                title:
                    "Somos o próximo passo na captação de recursos e investimentos verdes.",
                nft: {
                    title: "Captação de Recursos",
                    description:
                        "A BVM12 lidera a captação responsável de recursos na Amazônia, impulsionando o desenvolvimento sustentável. Ao conectar investidores a empresas locais, promovemos o equilíbrio entre crescimento econômico e preservação ambiental.",
                },
                esg: {
                    title: "Reflorestamento",
                    description:
                        "A BVM12 lidera o reflorestamento certificado e tokenizado por meio de blockchain na Amazônia, restaurando áreas degradadas. Contribuímos para a preservação ambiental, garantindo transparência e sustentabilidade em cada passo.",
                },
                co2: {
                    title: "Crédito de Carbono",
                    description:
                        "A BVM12 inova ao tokenizar créditos de carbono na Amazônia, utilizando blockchain. Essa solução permite a negociação eficiente no mercado balcão, impulsionando a preservação ambiental e oferecendo uma abordagem sustentável para investidores.",
                },
            },
            "get-started": {
                subtitle: "Iniciar",
                title: "Nunca foi tão fácil investir em ativos",
                cards: {
                    first: {
                        title: "Faça o seu cadastro em nossa plataforma.",
                        description:
                            "O cadastro é simples e rápido. Logo após concluí-lo, você terá uma conta para realizar transações financeiras.",
                    },
                    second: {
                        title: "Escolha um 'green token' de sua preferência.",
                        description:
                            "Após efetuar o cadastro em sua conta, você terá acesso ao home broker para comprar e vender seus green tokens.",
                    },
                    third: {
                        title: "Compre o token de forma segura na plataforma.",
                        description:
                            "Realize transações com segurança ao utilizar Palafita como método de pagamento na aquisição de tokens.",
                    },
                },
                "button-label": "Iniciar",
            },
        },

        "page-products": {
            name: "Produtos",
            "main-banner": {
                subtitle: "Nossas Soluções",
                title:
                    "Revolucionando a Sustentabilidade na Amazônia com Blockchain Verde.",
                description:
                    "Nossos produtos utilizam tecnologia e criatividade para gerar valor a região amazônica e ao meio ambiente",
                "join-button-label": "Junte-se ao nosso time",
                "our-company-button-label": "A história da nossa empresa",
            },

            "products": {
                "subtitle": "Reflorestamento",
                "title": "Temos a missão de gerar impacto ambiental e social na Amazônia",
                "description": "A BVM12 está comprometida com o reflorestamento da Amazônia, promovendo a recuperação de áreas degradadas e contribuindo para a preservação dos biomas locais. Nosso objetivo é restaurar a natureza e gerar um impacto positivo no meio ambiente.",
                "subtitle-section-two": "Créditos de Carbono",
                "title-section-two": "Uma solução eficaz para compensar emissões",
                "description-section-two": "Os créditos de carbono são uma ferramenta indispensável na luta contra as mudanças climáticas. A BVM12 conecta empresas a projetos certificados, permitindo a compensação de emissões e promovendo um futuro mais sustentável.",
                "subtitle-section-three": "Captação de Recursos",
                "title-section-three": "Democratizamos o acesso a fundos para impulsionar empresas",
                "description-section-three": "Oferecemos soluções para captação de recursos voltadas a startups com foco em sustentabilidade e empresas da região amazônica. Nosso objetivo é conectar empreendedores a investidores, fomentando projetos inovadores que promovam desenvolvimento econômico e ambiental."
            },

            "our-values": {
                "subtitle": "Nossos valores",
                "title": "Os valores que norteiam tudo o que fazemos",
                "inovation": {
                    "title": "Inovação",
                    "description": "Buscamos soluções criativas e tecnológicas que transformem desafios em oportunidades, promovendo mudanças sustentáveis e impactantes na Amazônia e no mundo."
                },
                "growth": {
                    "title": "Crescimento",
                    "description": "Acreditamos no desenvolvimento sustentável como pilar para um futuro equilibrado, promovendo crescimento econômico, social e ambiental em harmonia."
                },
                "passion": {
                    "title": "Paixão",
                    "description": "Somos movidos por um profundo compromisso com a preservação da natureza e a criação de um impacto positivo para as futuras gerações."
                },
            },
        },

        "page-ecotrade": {
            name: "EcoTrade",
            banner: {
                subtitle: "EcoTrade",
                title:
                    "Desenvolvida pela BVM12, a EcoTrade simplifica a venda direta de créditos de carbono para entregadores do iFood.",
                description:
                    "A plataforma permite aos entregadores listar e vender créditos nos aplicativos iFood, usando contratos inteligentes para segurança e transparência.",
            },
            story: {
                title1: "Integrada à Mastercard, proporciona uma experiência acessível e eficiente.",
                description1:
                    "Pagamentos podem ser recebidos em tokens ou moeda tradicional, ampliando as opções de negociação. Além disso, para incentivar práticas sustentáveis, a EcoTrade oferece descontos especiais para iFood e BVM12 ao adquirirem créditos de carbono diretamente dos entregadores. Além de recompensar esforços ecológicos, a EcoTrade fortalece a colaboração entre BVM12, iFood e Mastercard para uma economia mais verde.",
                title2: "Certificação dos Créditos de Carbono",
                description2:
                    "Na fase de certificação de emissões, a BVM12 adota uma abordagem abrangente. Coletamos dados detalhados de cada moto elétrica, incluindo modelo, eficiência e fonte de energia. Monitoramos continuamente o desempenho em tempo real, utilizando sensores e telemetria.",
                title3: "Certificação de Emissões das Motos",
                description3: {
                    mainText: "Ao estabelecer uma linha de base com as emissões de motos a combustão, a BVM12 certifica as não emissões de CO2, considerando também a origem da energia para carregar as baterias. Auditorias independentes garantem a validade e integridade dos dados, proporcionando uma certificação confiável e transparente.",
                    callToAction: "Junte-se a nós nesse movimento rumo a um futuro sustentável. A EcoTrade, da BVM12: conectando entregadores, plataformas e créditos de carbono para um impacto positivo no meio ambiente.",
                    projectDetails: "A EcoTrade é um projeto executado pela BVM12 - Plataforma de Captação para Startups Ltda. CNPJ 38.730.632/0001-83",
                }
            },
        },

        "page-about-us": {
            name: "Sobre nós",
            "main-banner": {
                subtitle: "Sobre nós",
                title: "Temos a missão de fomentar a economia na Amazônia.",
                description:
                    "Através da tecnologia e da criatividade temos trabalhado para o crescimento da economia e preservação da região amazônica, sempre com iniciativas sustentáveis e escaláveis.",
                "join-button-label": "Junte-se ao nosso time",
                "our-company-button-label": "A história da nossa empresa",
            },
            "why": {
                subtitle: "Por que?",
                title: "O que nos motiva a estarmos na Amazônia trabalhando?",
                description: "Nosso propósito fundamental é contribuir para a sustentabilidade e conservação desse ecossistema vital. Ao focar no 'Por quê', buscamos não apenas resultados tangíveis, mas também um impacto significativo e positivo no meio ambiente e nas comunidades locais. Estamos comprometidos com a missão de preservar a Amazônia para as gerações futuras, e é essa convicção que nos impulsiona diariamente em nosso trabalho na região."
            },
            "how": {
                subtitle: "Como?",
                title: "Como queremos fomentar a econmia na Amazônia?",
                description: "Na BVM12, utilizamos blockchain para simplificar a compra e venda de créditos de carbono, impulsionando o reflorestamento na Amazônia. Contratos inteligentes transparentes incentivam empresas a compensarem emissões. Plataformas digitais conectam investidores a projetos locais, captando crédito para empresas e garantindo rastreabilidade das ações de reflorestamento. Essa abordagem inovadora promove não apenas a preservação ambiental, mas também o desenvolvimento econômico sustentável na região, criando um impacto positivo duradouro."
            },
            "who-we-are": {
                subtitle: "O que somos?",
                title: "O que é uma Bolsa Verde?",
                description: "A BVM12 é uma plataforma de negócios da economia verde, utilizando blockchain para simplificar a transação de créditos de carbono e impulsionar o reflorestamento na Amazônia. Contratos inteligentes incentivam empresas a compensar emissões, enquanto plataformas digitais conectam investidores a projetos locais, captando crédito para empresas. Essa abordagem inovadora não só promove a preservação ambiental, mas também estimula o desenvolvimento econômico sustentável na região, gerando um impacto positivo e significativo."
            },
        },

        "page-contact": {
            name: "Contato",
            form: {
                subtitle: "CONTATE-NOS",
                title: "Entre em contato hoje mesmo",
                name: {
                    label: "Nome",
                    placeholder: "Digite seu nome",
                },
                email: {
                    label: "Email",
                    placeholder: "Digite seu e-mail",
                },
                phone: {
                    label: "Telefone",
                    placeholder: "Digite seu telefone",
                },
                subject: {
                    label: "Objetivo",
                    placeholder: "Digite seu objetivo",
                },
                message: {
                    label: "Em que podemos ajudar?",
                    placeholder: "Por favor, escreva sua mensagem aqui...",
                },
                "submit-button-label": "Enviar mensagem",
                "form-success": "Mensagem enviada com sucesso!",
            },
            "mid-banner": {
                subtitle: "BVM12",
                title: "Perguntas Frequentes",
            },
        },
    },
};