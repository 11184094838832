import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import guyImage from "../../assets/images/footer-guy-image.png";
import Box from "../../assets/images/box-icon.png";

import "./style.scss";
import Translator from "../Translator";
import { Link } from "react-router-dom";

const bannerClasses = {
  complete: ["footer-banner-wrap home-footer-banner-bg"],
  simple: ["footer-banner-wrap other-common-banner"],
};

const translatorId = "final-banner";

export default function FooterBanner({ isSimple, withButton = true }) {
  return (
    <section className={bannerClasses[isSimple ? "simple" : "complete"]}>
      <Container>
        <Row>
          <Col col={12} sm={6} md={6} lg={6} xl={6}>
            <div className="left-title-wrap">
              <h3>
                <Translator path={`${translatorId}.text`} />
              </h3>
              {withButton && (
                <Link to={"/contact"}>
                  <Button className="get-started-btn">
                    <Translator path="words.register" />
                  </Button>
                </Link>
              )}
            </div>
          </Col>
          <Col col={12} sm={6} md={6} lg={6} xl={6}>
            <div className="right-side-wrapper">
              <img src={isSimple ? Box : guyImage} alt="guy img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
